body {
    // background-color: transparent;
}


.w-100 {
    width: 100%;
}


.landingPageWrapper {
    .container {
        max-width: 1360px;
        width: 100%;
    }

    // .footer-copyright {
    //     p {
    //         font-size: 30px;
    //     }
    // }
}


.landingPageHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
}


@media (max-width: 767px) {
    #nav.nav-transparent {
        background-color: transparent;
    }
    #nav.nav-transparent:not(.fixed-nav) .navbar-brand .logo-alt {
        display: block!important;
    }
}






.formWrapper {
    .formHeader {
        background-color: #0c2e51;
        text-align: center;
        padding: 30px;

        .top,
        .bottom {
            font-size: 30px;
            text-transform: uppercase;
            line-height: 120%;
            font-weight: 500;

            &.top {
                color: #fff;
            }

            &.bottom {
                color: #fdae00;
            }
        }
    }

    .formContent {
        padding: 30px;
        background-color: #054a81;
        text-align: center;

        .top {
            font-size: 40px;
            line-height: 120%;
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;

            span {
                color: #f8ae03;
            }
        }

        form {
            margin-top: 30px;

            label,
            input {
                width: 100%;
            }

            input {
                max-width: 90%;

                &[type="text"],
                &[type="number"] {
                    background-color: rgba(255, 255, 255, 0.1);
                    border: 0;
                    border: 2px solid #fff;
                    border-radius: 5px;
                    padding: 15px;
                    height: auto;
                    font-size: 30px;
                    color: #fff;
                    font-weight: 500;

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.5);
                        text-transform: uppercase;
                        color: #fff;
                    }
                }

                &[type="submit"] {
                    margin-top: 20px;
                    display: inline-block;
                    padding: 0px 35px;
                    margin: 10px 0 0;
                    border: 2px solid transparent;
                    border-radius: 3px;
                    transition: 0.2s opacity;
                    font-weight: 500;
                    text-transform: uppercase;
                    font-size: 40px;
                    background-color: #f8ae03;
                    color: #fff;
                }
            }
        }
    }
}


.heroSection {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 50px;

    .heroContent {
        min-height: 100vh;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        align-items: center;

        .heroTitle {
            font-weight: 500;
            font-size: 6rem;

            .big {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 7.5rem;
            }
        }

        .desc {
            .descItem {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                margin: 10px 0;

                img {
                    width: 50px;
                    height: 50px;
                }

                span {
                    font-size: 40px;
                    font-weight: 500;
                }
            }
        }

        .buttonWrapper a {
            max-width: 80%;
            width: 100%;
            text-align: center;
            font-size: 50px;
            line-height: 100%;
        }

    }



    @media (max-width: 991px) {
        padding-top: 100px;

        .heroContent {
            grid-template-columns: 1fr;

            .formWrapper {
                margin: 30px 0;

                .formContent {
                    form {
                        input[type=submit] {
                            font-size: 30px;
                        }
                    }
                }
            }


            .buttonWrapper a {
                max-width: unset;
                font-size: 30px;
            }


            .heroTitle {
                font-size: 40px;

                .big {
                    font-size: 50px;
                }
            }
        }
    }
}





.blueStripSection {
    background-color: #054a81;

    .inner {
        text-align: center;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        img {
            display: block;
        }

        span {
            font-size: 50px;
            font-weight: 600;
            color: #fff;
        }
    }



    @media (max-width: 991px) {
        .inner {
            flex-direction: column;
            gap: 10px;

            span {
                font-size: 30px;
            }
        }
    }
}








.fourIconsSection {
    padding: 70px 0;
    background-color: #fff;

    .icon {
        text-align: center;
        width: 120px;
        height: 120px;
        margin: auto;
        margin-bottom: 16px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .iconTitle {
        display: block;
        text-align: center;
        font-size: 40px;
        line-height: 120%;
        color: #054a81;
        font-weight: 500;
    }

    .buttons {
        text-align: center;
        margin-top: 50px;

        .main-btn {
            font-size: 40px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: unset;
        }
    }



    @media (max-width: 991px) {
        .iconTitle {
            font-size: 30px;
            margin-bottom: 50px;
        }
    }
}









.whyChooseUsSection {
    background: #0e2648;
    padding: 70px 0;


    .section-header {
        .title {
            span {
                color: #f8ae03;
            }
        }
    }


    .icon {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background-color: #f6ae1b;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-bottom: -50px;
        position: relative;
        z-index: 1;

        img {
            width: 60%;
            height: 60%;
            object-fit: contain;
        }
    }

    .iconLabel {
        background-color: #fff;
        border-radius: 10px;
        padding: 30px 10px;
        padding-top: 60px;
        text-align: center;
        font-size: 40px;
        line-height: 120%;
        color: #054a81;
        font-weight: 500;
    }

    .buttons {
        text-align: center;
        margin-top: 50px;

        .main-btn {
            font-size: 40px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: unset;
        }
    }




    @media (max-width: 991px) {
        .iconLabel {
            font-size: 30px;
            margin-bottom: 50px;

            br {
                display: none;
            }
        }
    }
}












.uspSection {
    background-color: #fff;
    padding: 70px 0;

    .icon {
        width: 200px;
        height: 200px;
        margin: auto;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8ae03;
        border-radius: 100%;
        font-size: 90px;
        line-height: 100%;
        font-weight: 600;
        color: #fff;
        outline: 5px solid #054a81;
        border: 10px solid #fff;
    }

    .iconLabel {
        text-align: center;
        font-size: 60px;
        line-height: 120%;
        color: #054a81;
        font-weight: 600;
    }

    .buttons {
        text-align: center;
        margin-top: 50px;

        .main-btn {
            font-size: 40px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: unset;
        }
    }



    @media (max-width: 991px) {
        .iconLabel {
            font-size: 40px;
            margin-bottom: 50px;
        }
    }
}







.hairPatchBannerSection {
    background-color: #fff;
}









.faqSection {
    background-color: #054a81;
    padding: 80px 0;

    .listItem {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 16px;
        margin-bottom: 28px;

        .left {
            img {
                width: 30px;
            }
        }

        .right {
            .ques {
                font-size: 30px;
                line-height: 120%;
                color: #fff;
                font-weight: 500;
                margin-bottom: 10px;
            }
            .ans {
                font-size: 20px;
                line-height: 120%;
                color: #fff;
                font-weight: 300;
            }
        }
    }
}






.servicesSection {
    background-color: #fff;
    padding: 80px 0;


    .serviceCardItem {
        text-align: center;

        .imageWrapper {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .serviceCardText {
            .desc {
                font-size: 24px;
                color: #000;
                font-weight: bold;
                margin: 16px 0;

                span {
                    color: #054a81;
                }
            }

            .main-btn {
                cursor: pointer;
            }
        }
    }
}







.successStoriesSection {
    background-color: #fff;
    padding: 80px 0;

    img {
        margin: -60px 0;
        // pointer-events: none;
    }

    .buttons {
        text-align: center;
        margin-top: 50px;

        .main-btn {
            font-size: 40px;
            padding-top: 0;
            padding-bottom: 0;
            text-transform: unset;
        }
    }
}











.testimonialSection {
    padding: 80px 0;
    background-image: url("../../assets/img/testimonial-bg.png");

    .imgWrapper {
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }
}









.reachUsSection {
    background-color: #054a81;
    padding: 80px 0;

    .left {
        display: grid;
        gap: 20px;

        .item {
            background-color: #032b4c;
            padding: 30px;

            .label {
                padding: 0;
                font-size: 40px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 20px;
                display: block;
                text-align: left;
            }

            .details {
                font-size: 30px;
                color: #f8ae03;
            }
        }
    }

    iframe {
        height: 356px;
        border: 0;
    }



    @media (max-width: 991px) {
        .left {
            .item {
                .label {
                    font-size: 30px;
                }

                .details {
                    font-size: 20px;
                }
            }
        }

        iframe {
            margin-top: 30px;
        }
    }
}







.modal-open .modal {
    display: flex!important;
    align-items: center;

    .modal-body {
        padding: 0;
    }
}